
import { Options, Vue } from 'vue-class-component';

import IconFTPAccessIsClosed from '@/icons/other/FTPAccessIsClosed.vue';
import ModalChangeSubscription from '@/modals/ChangeSubscription.vue';
import { SUBSCRIPTION_PLAN } from '@/subscription';

@Options({
  components: {
    IconFTPAccessIsClosed,
    ModalChangeSubscription,
  },
})
export default class FTPAccessIsClosed extends Vue {
  public accessibilityPlan = [SUBSCRIPTION_PLAN.standard, SUBSCRIPTION_PLAN.premium];

  public showModalChangeSubscription = false;

  public closeModalChangeSubscription(): void {
    this.showModalChangeSubscription = false;
  }
}
