
import { Options, Vue } from 'vue-class-component';

import requests from '@/requests';
import { UserFTPCredentials } from '@/interface/profile.interface';
import { JobPricing } from '@/interface/pricing.interface';
import { EditedOptions, JobOptions } from '@/common/JobOptions/jobOptions.interface';
import FTPAccessIsClosed from './components/AccessIsClosed.vue';
import FTPCredentials from './components/Credentials.vue';
import FTPFolderSettings from './components/FolderSettings.vue';

@Options({
  components: {
    FTPAccessIsClosed,
    FTPFolderSettings,
    FTPCredentials,
  },
})
export default class SettingsPageFtp extends Vue {
  public credentials: UserFTPCredentials | null = null;

  public loaderCredentials = true;

  public options: JobOptions | null = null;

  public pricing: JobPricing | null = null;

  public editedOptions: { [key: string]: EditedOptions } = {};

  public saveChanges(): void {
    if (this.editedOptions) {
      this.changeShowModalLoader(true);

      requests.dashboard.updateOptions(this.editedOptions)
        .then((res) => {
          this.options = res.data;

          this.editedOptions = {};
        })
        .finally(() => {
          this.changeShowModalLoader(false);
        });
    }
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  created(): void {
    requests.dashboard.getFTPCredentials()
      .then((res) => {
        this.credentials = res.data;
      })
      .finally(() => {
        this.loaderCredentials = false;
      });

    requests.dashboard.getJobOptions().then((res) => {
      this.options = res.data;
    });

    requests.billing.getJobPricing().then((res) => {
      this.pricing = res.data;
    });
  }
}
