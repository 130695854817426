
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';

import SubscriptionCard from '@/common/SubscriptionCard.vue';
import { SUBSCRIPTION_PLAN } from '@/subscription';

@Options({
  components: {
    Modal,
    SubscriptionCard,
  },
  props: {
    title: String,
    plans: Array,
    showRates: Boolean,
  },
  emits: {
    close: Boolean,
    showRates: Boolean,
  },
})
export default class ModalChangeSubscription extends Vue {
  declare $props: {
    title: string;
    plans: SUBSCRIPTION_PLAN[];
    showRates: boolean;
  }

  public closeModal(): void {
    this.$emit('close', true);
  }

  public showDetailsRates(): void {
    this.$emit('showRates', true);
  }
}
