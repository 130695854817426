
import { Options, Vue } from 'vue-class-component';

import ClipboardCredentials from '@/common/ClipboardCredentials.vue';

import ModalNewFTPPassword from '@/modals/NewFTPPassword.vue';
import { UserFTPCredentials } from '@/interface/profile.interface';

@Options({
  components: {
    ClipboardCredentials,
    ModalNewFTPPassword,
  },
  props: {
    credentials: Object,
  },
})
export default class FTPCredentials extends Vue {
  declare $props: {
    credentials: UserFTPCredentials;
  }

  public creds!: UserFTPCredentials;

  public isShowModalNewFTPPassword = false;

  public ftpURL = process.env.VUE_APP_FTP_PASV_ADDRESS;

  public handleNewCredentials(credentials: UserFTPCredentials): void {
    this.creds = credentials;
  }

  created(): void {
    this.creds = this.$props.credentials;
  }
}
