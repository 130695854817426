
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';
import requests from '@/requests';

@Options({
  components: {
    Modal,
  },
  emits: {
    close: Boolean,
    save: Object,
  },
})
export default class ModalNewFTPPassword extends Vue {
  public password = ''

  public closeModal(): void {
    this.$emit('close', true);
  }

  public save(): void {
    this.changeShowModalLoader(true);

    requests.dashboard.updateFTPPassword(this.password)
      .then((res) => {
        this.$emit('save', res.data);
      })
      .finally(() => {
        this.changeShowModalLoader(false);
        this.closeModal();
      });
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }
}
