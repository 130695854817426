
import { Options, Vue } from 'vue-class-component';

import Accordion from '@/common/Accordion.vue';

import JobOptionsContainer from '@/common/JobOptionsContainer.vue';
import { JOB_TYPE, JOB_TYPE_TITLE } from '@/interface/job.interface';

import { getPriceByType } from '@/utils';
import { JobPricing } from '@/interface/pricing.interface';
import { EditedOptions, JobOptions } from '@/common/JobOptions/jobOptions.interface';

@Options({
  components: {
    Accordion,
    JobOptionsContainer,
  },
  props: {
    options: Object,
    pricing: Object,
  },
  emits: {
    edit: Object,
  },
})
export default class FTPFolderSettings extends Vue {
  declare $props: {
    options: JobOptions;
    pricing: JobPricing;
  }

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public listTypes: JOB_TYPE[] = [];

  public editedOptions: { [key: string]: EditedOptions } = {};

  public editOptions(type: keyof JobOptions, option: EditedOptions): void {
    if (JSON.stringify(this.$props.options[type]) === JSON.stringify(option)) {
      delete this.editedOptions[type];
    } else {
      this.editedOptions[type] = option;
    }

    this.$emit('edit', this.editedOptions);
  }

  public getJobPrice(type: JOB_TYPE): string {
    return getPriceByType(type, { ...this.$props.options, ...this.editedOptions }, this.$props.pricing);
  }

  created(): void {
    this.listTypes = Object.keys(this.$props.options) as JOB_TYPE[];
  }
}
