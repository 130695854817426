import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ftp-folder-settings__accordion--header" }
const _hoisted_2 = { class: "f-w-b" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobOptionsContainer = _resolveComponent("JobOptionsContainer")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listTypes, (type, id) => {
    return (_openBlock(), _createBlock(_component_Accordion, {
      key: id,
      open: id === 0
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.JOB_TYPE_TITLE[type]), 1),
          (_ctx.$props.options && _ctx.$props.pricing)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.getJobPrice(type)), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      body: _withCtx(() => [
        _createVNode(_component_JobOptionsContainer, {
          type: type,
          pricing: _ctx.$props.pricing,
          options: { ..._ctx.$props.options, ..._ctx.editedOptions },
          onEdit: ($event: any) => (_ctx.editOptions(type, $event))
        }, null, 8, ["type", "pricing", "options", "onEdit"])
      ]),
      _: 2
    }, 1032, ["open"]))
  }), 128))
}