
import { Options, Vue } from 'vue-class-component';
import { ShortJobInfoInstance } from '@/interface/job.interface';

import Modal from '@/common/base/Modal.vue';

import Purchase from '@/modals/components/Purchase.vue';
import { SUBSCRIPTION_PLAN } from '@/subscription';
import { PaymentMethod } from '@/interface/payment.interface';
import requests from '@/requests';
import methods from '@/methods';

@Options({
  components: {
    Modal,
    Purchase,
  },
  props: {
    job: Object,
    plan: String,
  },
  emits: {
    close: Boolean,
    subsUpdate: Boolean,
  },
})
export default class ModalChangeDefaultPM extends Vue {
  declare $props: {
    job: ShortJobInfoInstance;
    plan: SUBSCRIPTION_PLAN;
  }

  public paymentMethod: PaymentMethod | null = null;

  public loader = false;

  public updateSubscription(): void {
    this.loader = true;

    if (this.paymentMethod && this.$props.plan) {
      if (!this.paymentMethod?.default) {
        requests.billing.setDefaultPM(this.paymentMethod.stripe_id)
          .then(() => {
            this.changeSubscription();

            this.$store.commit('update', 'billing');
          })
          .catch(() => {
            // TODO: add show error;
            this.loader = false;
          });
      } else {
        this.changeSubscription();
      }
    }
  }

  private changeSubscription(): void {
    requests.billing.updateSubscription(this.$props.plan)
      .then(() => { this.updateProfile(); })
      .catch(() => {
        // TODO: add show error;
        this.loader = false;
      });
  }

  private updateProfile(): void {
    requests.profile.getProfile()
      .then((res) => {
        methods.store.setupProfile(res.data);

        this.$emit('subsUpdate', true);
      })
      .catch(() => { this.loader = false; });
  }

  public closeModal(): void {
    this.$emit('close', true);
  }
}
