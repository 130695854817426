
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import IconCheck from '@/icons/service/Check.vue';

import ModalUpdateSubscription from '@/modals/UpdateSubscription.vue';

import { ProfileInstance } from '@/interface/profile.interface';

import SubscriptionInfo from '@/common/SubscriptionInfo.vue';
import { SUBSCRIPTION_PLAN, subscriptions } from '@/subscription';
import store from '@/store';

@Options({
  components: {
    IconCheck,
    SubscriptionInfo,
    ModalUpdateSubscription,
  },
  props: {
    plan: String,
    titleButton: {
      type: String,
      default: 'Start now',
    },
  },
  computed: {
    ...mapState(['profile']),
  },
  emits: {
    close: Boolean,
  },
})
export default class SubscriptionCard extends Vue {
  declare $props: {
    plan: SUBSCRIPTION_PLAN;
    titleButton: string;
  }

  public isShowUpdateSubscription = false;

  public subscriptions = subscriptions;

  public selectPlan(emit: 'continue' | 'select'): void {
    if (emit === 'continue') {
      const profile: ProfileInstance = store.state.profile!;
      const subscription = { ...profile.subscription };

      if (subscription.end_date || subscription.downgrade_date) {
        this.isShowUpdateSubscription = true;
      } else {
        this.close();
      }
    } else {
      this.isShowUpdateSubscription = true;
    }
  }

  public close(): void {
    this.$emit('close', true);
  }
}
