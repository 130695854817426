<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M137.058 36.0676C137.058 65.8491 152.485 86.3005 152.485 100.651C152.485 115.001 144.236 145.14 78.2187 145.14C12.2016 145.14 2.50977 104.595 2.50977 88.8107C2.50977 14.8972 137.058 -16.6755 137.058 36.0676Z"
      fill="#fff"
    />
    <path d="M115.845 28.5049L118.889 25.4604L124.978 31.5494L121.934 34.5939L115.845 28.5049Z" fill="#646995" />
    <path d="M133.344 46.0156L136.388 42.9711L142.477 49.0601L139.433 52.1046L133.344 46.0156Z" fill="#646995" />
    <path d="M132.574 31.5293L138.663 25.4403L141.708 28.4848L135.619 34.5738L132.574 31.5293Z" fill="#646995" />
    <path
      d="M95.7987 125.937H39.8265C36.2615 125.937 33.3682 123.044 33.3682 119.479V74.2708C33.3682 70.7058 36.2615 67.8125 39.8265 67.8125H97.9515C101.516 67.8125 104.41 70.7058 104.41 74.2708V117.326C104.41 122.084 100.556 125.937 95.7987 125.937Z"
      fill="#F5F6FA"
    />
    <path
      d="M43.0557 119.479V74.2708C43.0557 70.7058 45.949 67.8125 49.514 67.8125H39.8265C36.2615 67.8125 33.3682 70.7058 33.3682 74.2708V119.479C33.3682 123.044 36.2615 125.937 39.8265 125.937H49.514C45.949 125.937 43.0557 123.044 43.0557 119.479Z"
      fill="#C4D6F0"
    />
    <path
      d="M48.4373 129.166H39.8262C34.483 129.166 30.1387 124.818 30.1387 119.479V74.2705C30.1387 68.9316 34.483 64.583 39.8262 64.583H97.9512C103.294 64.583 107.639 68.9316 107.639 74.2705V91.4927H101.18V74.2705C101.18 72.4923 99.7337 71.0413 97.9512 71.0413H39.8262C38.0437 71.0413 36.597 72.4923 36.597 74.2705V119.479C36.597 121.257 38.0437 122.708 39.8262 122.708H48.4373V129.166Z"
      fill="#383B53"
    />
    <path
      d="M94.7223 67.8122H88.264V51.6663C88.264 40.9843 79.5711 32.2913 68.889 32.2913C58.2069 32.2913 49.514 40.9843 49.514 51.6663V67.8122H43.0557V51.6663C43.0557 37.4193 54.6419 25.833 68.889 25.833C83.1361 25.833 94.7223 37.4193 94.7223 51.6663V67.8122Z"
      fill="#383B53"
    />
    <path
      d="M66.7357 129.166H60.2773V110.868C60.2773 106.713 63.6572 103.333 67.8121 103.333H77.4996V109.791H67.8121C67.2179 109.791 66.7357 110.274 66.7357 110.868V129.166Z"
      fill="#383B53"
    />
    <path
      d="M114.097 129.166H107.639V106.562C107.639 104.78 109.085 103.333 110.868 103.333H116.25C120.999 103.333 124.861 107.195 124.861 111.944C124.861 116.693 120.999 120.555 116.25 120.555H114.097V129.166ZM114.097 114.097H116.25C117.438 114.097 118.403 113.128 118.403 111.944C118.403 110.76 117.438 109.791 116.25 109.791H114.097V114.097Z"
      fill="#383B53"
    />
    <path d="M60.2773 114.097H77.4996V120.556H60.2773V114.097Z" fill="#383B53" />
    <path d="M89.3398 103.333H95.7982V129.166H89.3398V103.333Z" fill="#383B53" />
    <path d="M81.8057 103.333H103.333V109.791H81.8057V103.333Z" fill="#383B53" />
  </svg>
  <!-- eslint-enable -->
</template>
