import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fb6aee5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "purchase-modal__footer" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Purchase = _resolveComponent("Purchase")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    class: "purchase-modal",
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Purchase, {
        title: "Job purchase",
        onSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paymentMethod = $event))
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn fill t-t-u", { 'spinner': _ctx.loader, 'disabled': !_ctx.paymentMethod }]),
          disabled: !_ctx.paymentMethod || _ctx.loader,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateSubscription && _ctx.updateSubscription(...args)))
        }, " Update Subscription ", 10, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}