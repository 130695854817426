import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalNewFTPPassword = _resolveComponent("ModalNewFTPPassword")!
  const _component_ClipboardCredentials = _resolveComponent("ClipboardCredentials")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isShowModalNewFTPPassword)
      ? (_openBlock(), _createBlock(_component_ModalNewFTPPassword, {
          key: 0,
          onSave: _ctx.handleNewCredentials,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowModalNewFTPPassword = false))
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ClipboardCredentials, { value: _ctx.ftpURL }, null, 8, ["value"]),
    _createVNode(_component_ClipboardCredentials, {
      hidden: "",
      title: "Username",
      value: _ctx.creds?.login || '',
      class: "m-top-10 m-bottom-10"
    }, null, 8, ["value"]),
    _createVNode(_component_ClipboardCredentials, {
      hidden: "",
      title: "Password",
      value: _ctx.creds?.password || ''
    }, null, 8, ["value"]),
    _createElementVNode("button", {
      class: "btn border mini m-top-35 medium",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowModalNewFTPPassword = true))
    }, " New FTP password ")
  ], 64))
}